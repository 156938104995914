function handleLoadMore() {
    const btnsLoadMore = document.querySelectorAll('.w-dyn-list .loadmore-button');
    for (let btn of btnsLoadMore) {
        btn.addEventListener('click', (ev) => {
            ev.preventDefault();
            const parentList = btn.closest('.w-dyn-list');
            const appendNumber = parseInt(parentList.dataset.postsAppend) || 3;
            const currentVisibleLength = parentList.querySelectorAll('.w-dyn-item.visible').length;
            const allItems = [...parentList.querySelectorAll('.w-dyn-item')];
            for (let i = currentVisibleLength; i < currentVisibleLength + appendNumber && i < allItems.length; i++) {
                allItems[i].classList.add('visible');
                allItems[i].classList.remove('hidden');
            }
            if (currentVisibleLength + appendNumber > allItems.length) {
                btn.disabled = true;
                parentList.querySelector('.loadmore-less').disabled = false;
            }
        });
    }

    const btnsLoadLess = document.querySelectorAll('.w-dyn-list .loadmore-less');
    for (let btn of btnsLoadLess) {
        btn.addEventListener('click', (ev) => {
            ev.preventDefault();
            const parentList = btn.closest('.w-dyn-list');
            const initialMaxPosts = parentList.dataset.maxPosts || 3;
            const allItems = [...parentList.querySelectorAll('.w-dyn-item')];
            for (let i = allItems.length - 1; i >= initialMaxPosts; i--) {
                allItems[i].classList.add('hidden');
                allItems[i].classList.remove('visible');
            }
            btn.disabled = true;
            parentList.querySelector('.loadmore-button').disabled = false;
        });



    }


}

export default function () {
    handleLoadMore();
}
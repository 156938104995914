function intiMixitUp() {
    if ( !jQuery().mixItUp ) {
        return;
    }
    const args = {
        selectors: {
            target: ".w-dyn-item" // As in mixitup
        },
        loadmore: {
            // The number of items to start with
            initial: 6,
            // The number of additional items to show on click of the loadmore button
            loadMore: 3,
            // A selector string for the existing wrapper the buttons will be inserted into
            buttonWrapper: ".load-more-wrapper",
            // The class of the Load more button
            buttonClass: "loadmore-button",
            // The label of the Load more button
            buttonLabel: "Load more",
            // The class of the less button
            lessClass: "loadmore-less",
            // The label of the less button
            lessLabel: "Reset"
        }
    };
    let mixItUp1 = $('.post-list-wrapper-1').mixItUp(args);
    let mixItUp2;

    let activePanel = "1";

    const panels = document.querySelectorAll(".w-tab-pane");

    for ( let panel of panels ) {
        const observer = new MutationObserver(function () {
            setTimeout(() => {
                if ( panel.classList.contains('w-tab-pane-2') ) {
                    if ( activePanel === "1" ) {
                        if ( mixItUp2 ) {
                            mixItUp2.mixItUp('destroy');
                        }
                        mixItUp2 = $('.post-list-wrapper-2').mixItUp(args);
                        activePanel = "2";
                    }
                }
                if ( panel.classList.contains('w-tab-pane-1') ) {
                    if ( activePanel === "2" ) {
                        if ( mixItUp1 ) {
                            mixItUp1.mixItUp('destroy');
                        }
                        mixItUp1 = $('.post-list-wrapper-1').mixItUp(args);
                        activePanel = "1";
                    }
                }
            }, 200)
        });

        observer.observe(panel, {
            attributes: true,
            attributeFilter: ['style']
        });
    }


}

export default function () {
    intiMixitUp();

}